(function () {
    'use strict';

    const visitDetailPostOp = {
        bindings: {
            data: '=',
            questions: '<',
            patient: '<'
        },
        controller: visitDetailPostOpController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-detail-post-op.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailPostOp', visitDetailPostOp);

    visitDetailPostOpController.$inject = [];

    function visitDetailPostOpController() {
        const vm = this;

        vm.$onInit = function () {
            vm.questions = {
                '1': {
                    label: 'Comment se passe votre reprise alimentaire ? ',
                },
                '2': {
                    label: 'Avez-vous repris votre activité habituelle ?',
                },
                '3': {
                    label: 'Présentez-vous des nausées ou des vomissements ?',
                },
                '4': {
                    label: 'Avez-vous des douleurs depuis votre retour à la maison ?',
                },
                '5': {
                    label: 'Avez-vous repris un transit digestif ?',
                },
                '6': {
                    label: 'Avez-vous des difficultés pour uriner ?',
                },
                '7': {
                    label: 'Avez-vous de la température supérieure à 37°',
                },
                '8': {
                    label: 'Présentez-vous un saignement sur la zone opératoire (cicatrice ou au niveau du vagin) ?',
                },
                '9': {
                    label: 'La cicatrice est elle inflammatoire? (rougeur, douleur, chaleur, œdème) ?',
                },
                '10': {
                    label: 'Y a t il un écoulement purulent au niveau de la cicatrice ?',
                },
                '11': {
                    label: 'Avez vous une douleur dans un mollet ?',
                },
                '12': {
                    label: 'Merci de renseigner votre Tension Artérielle?',
                },
                '13': {
                    label: 'Merci de renseigner votre pouls ?',
                },
            };

        };
    }

})();
