(function () {
    'use strict';

    const visitDetailPreOp = {
        bindings: {
            data: '=',
            questions: '<',
            patient: '<'
        },
        controller: visitDetailPreOpController,
        controllerAs: 'vm',
        templateUrl: 'app/activity/visit/visit-detail-pre-op.html'
    };

    angular
        .module('continuumplatformApp')
        .component('visitDetailPreOp', visitDetailPreOp);

    visitDetailPreOpController.$inject = [];

    function visitDetailPreOpController() {
        const vm = this;

        vm.$onInit = function () {
            vm.questions = {
                '1': {
                    label: 'En cas de traitements habituels, avez-vous reçu des consignes particulières ?',
                },
                '2': {
                    label: "Nous vous rappelons que la présence d'un accompagnant sera indispensable pour votre retour à domicile et pour la première nuit. Avez-vous organisé votre retour ?",
                },
                '3': {
                    label: 'Acceptez-vous, tel que prévu,  la réalisation de cette procédure en ambulatoire ? ',
                },
                '4': {
                    label: 'Avez-vous pensé à remplir le papier désignant votre personne de confiance (Il est nécessaire de le remplir et de le ramener lors de votre hospitalisation) ?',
                },
                '5': {
                    label: "Avez- vous pensé à acheter les médicaments antalgiques et antinauséeux prescrits lors de la consultation d'anesthésie ?",
                },
            };

        };
    }

})();
