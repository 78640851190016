(function () {
    'use strict';
    angular
        .module('continuumplatformApp')
        .factory('Visit', Visit);

    Visit.$inject = ['$resource', '$translate', 'Principal', 'DateUtils', 'Practitioner'];

    function Visit($resource, $translate, Principal, DateUtils, Practitioner) {
        var resourceUrl = 'api/visits/:id';

        var service = $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true},
            'billableOwn': {
                url: 'api/visits/billable-own',
                method: 'GET',
                isArray: true
            },
            'billableByEntity': {
                url: 'api/visits/billable-by-entity',
                method: 'GET',
                params: {
                    facilityId: null
                },
                isArray: true
            },
            'get': {
                method: 'GET',
                transformResponse: transformReponse
            },
            'getQuestions': {
                url: 'api/visits/:id/questions',
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': {method: 'PUT'},
            'save': {
                url: 'api/visits',
                method: 'POST',
                transformResponse: function (data) {
                    if (data) {
                        return {id: data};
                    }
                    return null;
                }
            },
            'preCreate': {
                url: 'api/visits/pre-create',
                method: 'POST',
                transformResponse: transformReponse
            },
            'createOwn': {
                url: 'api/visits/create-own',
                method: 'POST',
                transformResponse: function (data) {
                    updateCountStartableOrResumableVisit();
                    if (data) {
                        return {id: data};
                    }
                    return null;
                }
            },
            'plan': {
                url: 'api/visits/:id/plan',
                method: 'PUT'
            },
            'confirm': {
                url: 'api/visits/:id/confirm',
                method: 'PUT'
            },
            'undoConfirm': {
                url: 'api/visits/:id/undo-confirm',
                method: 'PUT'
            },
            'start': {
                url: 'api/visits/:id/start',
                method: 'PUT',
                transformResponse: transformReponse
            },
            'draft': {
                url: 'api/visits/:id/draft',
                method: 'PUT',
            },
            'finish': {
                url: 'api/visits/:id/finish',
                method: 'PUT',
                interceptor: {
                    response: function(response) {
                        updateCountStartableOrResumableVisit();
                        return response;
                    }
                }
            },
            'abort': {
                url: 'api/visits/:id/abort',
                method: 'PUT',
                interceptor: {
                    response: function(response) {
                        updateCountStartableOrResumableVisit();
                        return response;
                    }
                }
            },
            'reply': {
                url: 'api/visits/:id/reply',
                method: 'POST'
            },
            'cancel': {
                url: 'api/visits/:id/cancel',
                method: 'POST'
            },
            'acknowledge': {
                url: 'api/visits/:id/acknowledge',
                method: 'POST'
            },
            'rename': {
                url: 'api/visits/:id/rename',
                method: 'POST'
            },
            'comment': {
                url: 'api/visits/:id/comment',
                method: 'POST'
            },
            'reopen': {
                url: 'api/visits/:id/reopen',
                method: 'POST',
            },
            'queryDistinctTherapies': {
                url: 'api/visits/distinct-therapies',
                method: 'GET',
                isArray: true
            },
            'queryDistinctPrograms': {
                url: 'api/visits/distinct-programs',
                method: 'GET',
                isArray: true
            },
            'queryDistinctFacilityDoctors': {
                url: 'api/visits/distinct-facility-doctors',
                method: 'GET',
                isArray: true
            },
            'exportPdf': {
                url: 'api/visits/:id/export',
                method: 'GET',
                cache: false,
                responseType: 'arraybuffer',
                headers: {
                    'Accept': 'application/pdf',
                },
                transformResponse:
                    function (data, headers) {
                        return {
                            data: new Blob([data], {type: 'application/pdf'}),
                            headers: headers
                        };
                    }
            }
        });


        service.getFrequencies = function () {
            return [
                'DAILY',
                'WEEKLY',
                'EVERY2WEEKS',
                'EVERY3WEEKS',
                'EVERY4WEEKS',
            ];
        };

        service.isStartable = function (visit) {
            return visit.actions.includes('START');
        };

        service.isResumable = function (visit) {
            return visit.actions.includes('RESUME');
        };

        service.availableMembers = function (type, team, patient) {
            return new Promise((resolve, reject) => {

                const additionalTeamMemberPromise = [];

                let availableMembers = team.filter(m => {
                    switch (type) {
                    case 'STD_HOME':
                        return ['NURSING', 'NURSING_HOME', 'HAH', 'NURSE'].includes(m.role)  && m.entity.legalSubStatus !== 'SUBSTITUTE';

                    case 'PLUS_HOME':
                        return 'NURSE_PLUS' === m.role;

                    case 'NCH_PHONE':
                        return 'NURSE_COORD' === m.role;

                    case 'NCC_PHONE':
                        if (['COORDINATION_FACILITY'].includes(m.role)) {
                            additionalTeamMemberPromise.push(getAdditionalTeamMember(['J10', 'J21', 'J38', 'J60'], m.role, m.entity.id));
                        }
                        return m.role === 'COORDINATION_FACILITY';

                    case 'GUIDANCE':
                        if (['COORDINATION_FACILITY'].includes(m.role)) {
                            additionalTeamMemberPromise.push(getAdditionalTeamMember(['J10', 'J21', 'J38', 'J60'], m.role, m.entity.id));
                        }
                        return m.role === 'COORDINATION_FACILITY';

                    case 'HOSPITAL':
                        return 'HOSPITAL' === m.role;

                        case 'HAH':
                            return ['HOSPITAL', 'HAH'].includes(m.role);

                        case 'PHARMA_CONSULT':
                        return ['HOSPITAL', 'FACILITY_PHARMACIST', 'PHARMACY'].includes(m.role);

                    case 'PHARMA_ITW':
                        return 'PHARMACY' === m.role;

                    case 'MEDICAL_CONSULT':
                        return m.entity.id === patient.healthFacilityId
                            || (m.entity.job === 'J10' && m.entity.healthFacilityId === patient.healthFacilityId);

                    case 'ANNOUNCEMENT_CONSULTATION':
                        return m.entity.id === patient.healthFacilityId
                            || (m.entity.job === 'J60' && m.entity.healthFacilityId === patient.healthFacilityId);

                    case 'MEDICAL_CONSULT_EXT':
                        return (['HOSPITAL', 'NURSING_HOME', 'HAH'].includes(m.role) && m.entity.id !== patient.healthFacilityId)
                            || (m.entity.job === 'J10' && (!m.entity.healthFacilityType || m.entity.healthFacilityId !== patient.healthFacilityId));

                    case 'RENEWAL_CONSULT':
                        return m.entity.id === patient.healthFacilityId || m.entity.healthFacilityId === patient.healthFacilityId;

                    default:
                        return true;
                    }
                });

                // on ajoute les professionals de santé additionel à la liste des membres possibles
                // on dédoublonne et on trie le résultat avant de le retourner.
                Promise.all(additionalTeamMemberPromise).then((additionalTeamMember) => {
                    availableMembers = availableMembers.concat(additionalTeamMember.flat());

                    const membersMap = new Map();
                    availableMembers.forEach((m) => {
                        if (!membersMap.get(m.entity.id)) {
                            membersMap.set(m.entity.id, m);
                        }
                    });
                    resolve([...membersMap.values()].sort((m1, m2) => m1.role.localeCompare(m2.role)));
                });
            });

        };

        function getAdditionalTeamMember(jobs, role, healthFacilityId) {
            return Practitioner.query({'job.in': jobs, 'healthFacilityId.equals': healthFacilityId})
                .$promise
                .then((pList) => {
                    return pList.map((practitioner) => ({
                        role: role,
                        type: "Practitioner",
                        entity: practitioner
                    }));
                });
        }


        service.visitTypes = [
            'PRO',
            'BIO',
            'HOSPITAL',
            'HAH',
            'NCH_PHONE',
            'NCC_PHONE',
            'GUIDANCE',
            'STD_HOME',
            'PLUS_HOME',
            'PHARMA_ITW',
            'PHARMA_CONSULT',
            'MEDICAL_CONSULT',
            "ANNOUNCEMENT_CONSULTATION",
            'MEDICAL_CONSULT_EXT',
            'RENEWAL_CONSULT'
        ];

        /**
         * Generate the visit types list available for the user
         * @return {*[]} - an array of the visit types
         */
        service.getVisitTypeForUser = (role) => {
            if(!role) {
                return [];
            }
            switch (role) {
            case 'COORDINATION_FACILITY':
                return [
                    'BIO',
                    'NCC_PHONE',
                    'GUIDANCE',
                ];
            case 'FACILITY_DOCTOR':
                return [
                    'BIO',
                    'HOSPITAL',
                    'MEDICAL_CONSULT',
                    'RENEWAL_CONSULT',
                ];
            case 'NURSE_COORD':
                return [
                    'HOSPITAL',
                    'NCH_PHONE',
                    'ANNOUNCEMENT_CONSULTATION',
                    'RENEWAL_CONSULT',
                    'BIO',
                ];
            case 'FACILITY_PHARMACIST':
                return [
                    'HOSPITAL',
                    'PHARMA_CONSULT',
                    'RENEWAL_CONSULT',
                    'BIO',
                ];
            }
        };

        service.visitPricings = {
            'STD_HOME': ['STD_HOME_FIRST', 'STD_HOME', 'STD_HOME_LAST'],
            'PLUS_HOME': ['PLUS_HOME'],
            'PHARMA_ITW': ['PICTO', 'PHARMA_ITW_FIRST', 'PHARMA_ITW', 'PHARMA_ITW_LAST'],
            'MEDICAL_CONSULT_EXT': ['MEDICAL_CONSULT_EXT']
        };

        service.visitStatuses = [
            'PAUSED',
            'PENDING',
            'CONFIRMED',
            'TODO',
            'IN_PROGRESS',
            'DONE',
            'CANCELED',
            'EXPIRED'
        ];

        service.getSteps = function() {

            const codes = [
                'preVisit',
                'PHARMA_ITW_1_STEP1',
                'PHARMA_ITW_1_STEP1_PICTO',
                'PATIENT_PROFILE',
                'PHARMA_ITW_EVAL',
                'PHARMA_ITW_QOL_PICTO',
                'PHARMA_ITW_1_STEP2',
                'PHARMA_ITW_1_STEP2_PICTO',
                'PHARMA_ITW_EVAL_PICTO',
                'PHARMA_ITW_LIFE',
                'PHARMA_ITW_LIFE_PICTO',
                'measures',
                'biology',
                'pain',
                'PHARMA_ITW_TREATMENT_PICTO',
                'PHARMA_ITW_TREATMENT',
                'PHARMA_ITW_TREATMENT_PLAN',
                'symptoms',
                'adherence',
                'QOL_EQ_5D_5L',
                'QOL_QLQ_C30',
                'IMPORT_PRESCRIPTIONS',
                'PHARMA_ITW_ADHERENCE_BIS',
                'PHARMA_ITW_INTERACTIONS_PICTO',
                'PHARMA_ITW_PPS',
                'PHARMA_ITW_1_PREV',
                'PHARMA_ITW_1_PREV_PICTO',
                'PHARMA_ITW_MODALITES_PRISE_STEP1_PICTO',
                'PHARMA_ITW_END_REVIEW_PICTO',
                'actions',
                'end',
                'PHARMA_ITW_END',
                'INTAKE_RECORD',
                'END_REPORT',
                'EPICES',
                'ANNOUNCEMENT_CONSULTATION_SYNTHESIS',
                'EMOTIONAL_PATIENT_PROFILE',
                'UNDERSTANDING_TREATMENT',
                'ORIENTATION',
                'ONCOLINK_COORDINATION',
                'HEMAVIE_TOPICS_ADDRESSED',
                'AOH_CONTROL_TEST',
                "AOH_SYMPTOMS",
                'AOH_CRISIS_EVALUATION',
                'DATE_OF_NEXT_MEDICAL_CONSULTATION',
                'PRE_OP_UCA_TOUS_PARCOURS',
                'POST_OP_RAAC_GYNECO',

            ];

            const keys = codes.map(function(code) {
                return "continuumplatformApp.visit.steps." + code;
            });

            return $translate(keys).then(function (translations) {
                return codes.map(function(code) {
                    return ({
                        code: code,
                        label: translations["continuumplatformApp.visit.steps." + code]
                    });
                });
            }, function (translationIds) {
                //?
            });
        };

        service.countStartableOrResumableVisit = function(practitionerId) {
            this.query({
                size: 1000,
                feasibleByPractitionerId: practitionerId,
                "status.in": ['TODO', 'IN_PROGRESS'],
            }).$promise.then((data, headers) => {
                const startableOrResumableVisite = data.filter((v) => this.isStartable(v) || this.isResumable(v));
                service.totalVisitTodoOrInProgress = startableOrResumableVisite.length;
            });
        };

        service.setCountStartableOrResumableVisit = (count) => {
            service.totalVisitTodoOrInProgress = count;
        };

        return service;

        function updateCountStartableOrResumableVisit() {
            // permet de mettre à jour le compteur de visite à faire
            Principal.identity().then((account) => {
                const practitioner = account.practitioner;
                let isCoordination, isHospitalUser, isCrpvUser;

                if (practitioner) {
                    isCoordination = practitioner.healthFacilityType === 'COORDINATION';
                    isHospitalUser = practitioner.healthFacilityType === 'HOSPITAL';
                    isCrpvUser = practitioner.healthFacilityType === 'PV';
                }

                if (!isCoordination && !isHospitalUser && !isCrpvUser) {
                    const practitionerId = practitioner ? practitioner.id : undefined;
                    service.countStartableOrResumableVisit(practitionerId);
                }

            });
        }

        function transformReponse(data) {
            if (data) {
                data = angular.fromJson(data);
                data.dueDate = DateUtils.convertDateTimeFromServer(data.dueDate);
                data.plannedDate = DateUtils.convertDateTimeFromServer(data.plannedDate);
                data.limitDate = DateUtils.convertDateTimeFromServer(data.limitDate);
                data.startDate = DateUtils.convertDateTimeFromServer(data.startDate);
                data.date = DateUtils.convertDateTimeFromServer(data.date);
                data.nextReminderDate = DateUtils.convertDateTimeFromServer(data.nextReminderDate);
                data.ackDate = DateUtils.convertDateTimeFromServer(data.ackDate);
                data.confirmDate = DateUtils.convertDateTimeFromServer(data.confirmDate);
            }
            return data;
        }


    }
})();
